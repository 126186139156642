<template>
  <div class="app-container">
    <!-- <div class="filter-container">
      <el-select
        v-model="listQuery.school_id"
        placeholder="请选择学校"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option
          v-for="item in schools"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-date-picker
        v-model="value1"
        type="date"
        placeholder="选择日期"
        @change="pickerChange"
        style="margin-left: 10px"
      >
      </el-date-picker>
      <el-button class="filter-item" type="primary" @click="getTime(0, value1)">
        查找
      </el-button>
    </div> -->
    <div class="calendar">
      <div class="calendar_top">
        <div class="c_top_left">{{ year }}</div>
        <div class="c_top_right">
          <el-button-group>
            <el-button type="plain" @click="getTime(2)"> 上周 </el-button>
            <el-button type="plain" @click="getTime(0)"> 今天 </el-button>
            <el-button type="plain" @click="getTime(1)"> 下周 </el-button>
          </el-button-group>
        </div>
      </div>
      <el-divider style="margin: 10px 0"></el-divider>
      <div class="calendar_centont">
        <div class="calendar_c_top">
          <div style="margin-top: 28px">
            <div v-for="jj in classKnob" :key="jj.id" class="broadside">
              {{ jj.name }}
            </div>
          </div>
          <div v-for="i in week" :key="i.id" class="calendar_item-top">
            <div style="margin-bottom: 10px">
              {{ i.name }}&nbsp;&nbsp;&nbsp;{{ i.data }}
            </div>
            <div
              class="calendar_item-centont"
              v-for="kk in classKnob"
              :key="kk.id"
            >
              <div
                v-for="(item, key) in events"
                :key="key"
                v-if="kk.name == item.class_time_config.name"
              >
                <div v-if="i.time == item.date" class="calendar_tag">
                  <!-- <p>课节:{{ item.class_time_config.name }}</p> -->
                  <p>课程名称:{{ item.class_course.school_course.name }}</p>
                  <p>上课教师:{{ item.class_course.teacher.name }}</p>
                  上课地点:{{ item.classroom }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      date: "",
      events: [],
      listQuery: {
        school_id: "",
        class_id: "",
        year: "",
        month: "",
      },
      value: new Date(),
      schoolList: [],
      schools: [],
      week: [],
      classKnob: [
        { id: 1, name: "第1节" },
        { id: 2, name: "第2节" },
        { id: 3, name: "第3节" },
        { id: 4, name: "第4节" },
        { id: 5, name: "第5节" },
        { id: 6, name: "第6节" },
        { id: 7, name: "第7节" },
        { id: 8, name: "第8节" },
        { id: 9, name: "第9节" },
        { id: 10, name: "第10节" },
        { id: 11, name: "第11节" },
        { id: 12, name: "第12节" },
      ],
      value1: "",
    };
  },
  created() {
    this.listQuery.class_id = this.$route.query.class_id;
    this.getTime(0);
    this.listQuery.year = new Date().getFullYear();
    this.listQuery.month = new Date().getMonth() + 1;
    // this.getSchedule();
  },
  mounted() {},
  methods: {
    change(e) {
      this.value = e;
      this.listQuery.year = e.getFullYear();
      this.listQuery.month = e.getMonth() + 1;
      this.getSchedule();
    },
    pickerChange(e) {
      this.getTime(0, e);
    },
    getTime(time, chackData) {
      //time = 7下周2  -7上周1   0本周0
      if (time == 1) {
        this.time += 7;
      } else if (time == 2) {
        this.time -= 7;
      } else {
        this.time = 0;
      }
      let date = new Date(); //this.chaoZuoTime
      let currentFirstDate = new Date(date);
      if (chackData) {
        currentFirstDate = new Date(chackData);
      }
      let formatDate = function (date) {
        let year = date.getFullYear(); //年
        let month = date.getMonth() + 1; //月
        let day = date.getDate(); //日;
        let week = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"][
          date.getDay()
        ];
        //我需要的格式是  （周几 月.日） 这里看你们的需求
        return week + "-" + month + "-" + day + "-" + year;
      };

      let addDate = function (date, n) {
        date.setDate(date.getDate() + n);
        return date;
      };

      let setDate = function (date) {
        let week = date.getDay() - 1; //控制 日开头还是一开头
        date = addDate(date, week * -1);
        currentFirstDate = new Date(date);
        let weekList = [];
        for (let i = 0; i < 7; i++) {
          weekList[i] = formatDate(i == 0 ? date : addDate(date, 1));
        }
        return weekList;
      };
      let thisWeek = setDate(addDate(currentFirstDate, this.time)); //本周
      let list = [];
      thisWeek.forEach((item) => {
        let sp = item.split("-");
        this.year = sp[3] + "年" + sp[1] + "月";
        if (sp[2] < 10) {
          sp[2] = "0" + sp[2];
        }
        list.push({
          name: sp[0],
          data: sp[1] + "·" + sp[2],
          time: sp[3] + "-" + (sp[1] < 10 ? "0" + sp[1] : sp[1]) + "-" + sp[2],
        });
      });
      this.week = list;
      list = [];
      this.listQuery.start_at = this.week[0].time;
      this.listQuery.end_at = this.week[this.week.length - 1].time;
      this.getSchedule();
    },
    getSchoolList() {
      request({
        url: "/api/teacherend/school/list",
        method: "get",
        params: {
          limit: 99999999999999999,
        },
      }).then((response) => {
        this.schools = response.data.data;
      });
    },
    getSchedule() {
      request({
        url: "/api/teacherend/schedule/list",
        method: "get",
        params: this.listQuery,
      }).then((res) => {
        if (res.code == 0) {
          this.events = res.data;
          this.$forceUpdate()//强制更新
        }
      });
    },
  },
};
</script>
<style lang="scss" >
.filter-container {
  display: flex;
  align-items: center;
  .filter-item {
    margin: 0;
    margin-left: 10px;
  }
}
.el-calendar__button-group {
  display: none !important;
}
.el-calendar-day {
  height: 110px !important;
  overflow: hidden;
  overflow-y: auto;
}
.dateStyle {
  color: #000;
  background: #c7e6fd;
  margin-top: 10px;
}
.calendar {
  .calendar_top {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
  }
  .calendar_centont {
    .calendar_c_top {
      display: flex;
      .calendar_item-top {
        flex: 1;
        text-align: center;
        &:first-child .calendar_item-centont {
          border-left: 1px solid #dcdfe6;
        }
        .calendar_item-centont {
          min-height: 130px;
          height: 130px;
          border: 1px solid #dcdfe6;
          border-left: none;
          overflow: hidden;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
          .calendar_tag {
            color: #1890ff;
            background-color: #e8f4ff;
            border-color: #d1e9ff;
            border-radius: 4px;
            text-align: left;
            padding: 10px;
            margin: 10px;
            p {
              margin: 5px 0;
            }
          }
        }
      }
    }
  }
}
.broadside {
  height: 130px;
  line-height: 130px;
  border: 1px solid #dcdfe6;
  // margin-top: 0px;
  text-align: center;
}
</style>